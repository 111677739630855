.container{
    background-color:#000;
    width: 100%;
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content:space-around;  
    
} 

.googleplay{
  padding-top: 5px;
}

.appstore{
  padding-top: 8px;
}

.formulario{
    justify-content: space-between;
    margin-right: 100px;
    

}

h2{
    background-color: #000;
    color:#fff;
    font-size: 30px; 
}

.subject{
    flex-direction: column;
    align-items: center;
    justify-items: center;
    border-radius: 8px;
    padding: 8px 14px;
    margin-top: 14px;
    padding-top: 8px;
    margin-bottom: 8px;
    height: 40px;
    width: 100%;
    outline: none;
}

.assunto{
    flex-direction: column;
    align-items: center;
    justify-items: center;
    border-radius: 8px;
    padding-top: 8px;
    padding-left: 10px;
    height: 150px;
    width: 390px;
    outline: none;
}

.botao{
    flex-direction: row;
    align-items: center;
    justify-items: center;
    border-radius: 8px;
    padding-top: 8px;
    padding: 8px 14px;
    background-color: #DCBC2E;
    margin-top: 8px;
    width:390px;
}

.message{
    text-align: left;
    color:#fff;
    padding-top: 8px;
    background-color: #000;
    font-size: 25px;
}
.title{
    text-align: center;
    flex-direction: column;
    justify-content: center;
    font-size:40px ;
    background-color: #000;
    color:#DCBC2E;   
    font-family: 'Montserrat', sans-serif;
}

.emailN1{
    font-size: 30px;
    color: #DCBC2E;
    font-weight: bold;
    
} 

.chat-image{
max-width: 100%;
}
@media screen and (max-width: 1000px) {
    .container {
      flex-direction: column;
      align-items: center;
      justify-content: center
    }
  }
  @media screen and (max-width: 1000px) {
    .message {
      flex-direction: column;
      margin:0;
      text-align: center;
    }
  }
  @media screen and (max-width: 1000px) {
    .formulario {
      max-width: 100%;
      margin-right: 0px;
     
    }
  }
  @media screen and (max-width: 1000px) {
    .nome , .email, .assunto, .botao{
     width: 350px;
     justify-content:center;
    }
  }
  @media screen and (max-width: 1000px) {
    .chat-image{
     max-width: 100%;
     margin-left: 35px;
    }
  }



